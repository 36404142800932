import React from "react";
import './Branding.css';

const Brading = () => {
  const data = [
    {
      cover: "./images/slack.jpg",
    },
    {
      cover: "./images/sheet.jpg",
    },
    {
      cover: "./images/wix.jpg",
    }
    ,
    {
      cover: "./images/out.jpg",
    },
  ]
  return (
    <div>
      <section className='Brading'>
        <div className='container grid2'>
          {data.map((val) => {
            return (
              <div className='box'>
                <img src={val.cover} alt='' />
              </div>
            )
          })}
        </div>
      </section>
    </div>
  )
}

export default Brading
