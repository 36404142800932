import React from "react";
import './Footer.css';

const Footer = () => {
  return (
    <>
      <footer>
        <p>© 2022 by Lungelo Keswa. All rights reserved.</p>
      </footer>
    </>
  )
}

export default Footer
