import React from "react"

const Skill = () => {
  const data = [
    {
      title: "I have high skills in NoCode",
      decs: "I am working at a professionl level with these tools i have also specified my level on them",
    },
  ]

  const Progress = ({ done, title }) => {
    return (
      <div className='progress'>
        <div
          className='progress-done'
          style={{
            opacity: 1,
            width: `${done}%`,
          }}
        >
          <h4> {title}</h4>
          <h4> {done}%</h4>
        </div>
      </div>
    )
  }

  return (
    <>
      <section className='skill mtop'>
        <div className='container flexSB'>
          {data.map((value) => {
            return (
              <div className='left row'>
                <h1>{value.title}</h1>
                <p>{value.decs}</p>
              </div>
            )
          })}
          <div className='right row'>
            <Progress done='95' title='MailChimp' />
            <Progress done='80' title='Hubspot' />
            <Progress done='90' title='Sharpspring' />
            <Progress done='95' title='Wordpress' />
            <Progress done='80' title='Engagebay' />
            <Progress done='70' title='Shopify' />
            <Progress done='100' title='Slack' />
            <Progress done='70' title='Wix' />
          </div>
        </div>
      </section>
    </>
  )
}

export default Skill
