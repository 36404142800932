
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import About from './components/About/About';
import Portfolio from './components/Portfolio/Portfolio';
import Skills from './components/Skills/Skills';
import Wrapper from './components/Wrapper/Wrapper';
import Branding from './components/Branding/Branding';
import Testimonials from './components/Testimonials/Testimonials';
import Work from './components/Work/Work';
import Footer from './components/Footer/Footer'

// import { Header, Home, About, Portfolio, Skills, Wrapper, Branding, Testimonials, Work, Footer } from './components';

function App() {
  return (
    <>
     <Header />
     <Home />
     <About />
     <Portfolio />
     <Skills />
     {/* <Wrapper /> */}
     <Branding />
     {/* <Testimonials /> */}
     <Work />
     <Footer />
  </>
  );
}

export default App;
