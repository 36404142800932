import React from "react";
import './Work.css';

const Work = () => {
  return (
    <>
      <section className='work'>
        <div className='container'>
          <h1>Let's Work Together!</h1>
          {/* <button className='primary-btn'>Make an enquiry</button> */}
        </div>
      </section>
    </>
  )
}

export default Work
