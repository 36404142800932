const data = [
  {
    id: 1,
    cover: "./images/por1.jpg",
    title: "MailChimp",
    //category: "Design",
  },
  {
    id: 2,
    cover: "./images/por2.jpg",
    title: "Hubspot",
   // category: "Branding",
  },
  {
    id: 3,
    cover: "./images/por3.jpg",
    title: "Sharpspring",
   // category: "Photography",
  },
  {
    id: 4,
    cover: "./images/por4.jpg",
    title: "Wordpress",
   // category: "Design",
  },
  {
    id: 5,
    cover: "./images/por5.jpg",
    title: "Engagebay",
   // category: "Photography",
  },
  {
    id: 6,
    cover: "./images/por6.jpg",
    title: "Shopify",
   // category: "Branding",
  },
  {
    id: 7,
    cover: "./images/por7.jpg",
    title: "Slack",
   // category: "Branding",
  },
]

export default data
